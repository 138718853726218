<template>
  <BCard class="p-0">
    <div class="flex flex-row items-center justify-between">
      <div class="flex">
        <BButton
          variant="primary"
          size="sm"
          class="mr-1 rounded-lg p-0"
          @click="$router.go(-1)"
        >
          <feather-icon
            size="2x"
            icon="ChevronLeftIcon"
          />
        </BButton>
        <div class="text-black font-semibold m-0 text-2xl">
          Detail Kartu
        </div>
      </div>
    </div>
    <BOverlay
      variant="transparent"
      :show="!cards.id"
    >
      <div class="mt-[20px] wrapper_card">
        <div>
          <div>
            <div class="flex justify-center">
              <CCDetail
                :card-label="cards.label"
                :card-number="`xxxx xxxx xxxx xxxx ${cards.card_number}`"
                :status="cards.status"
                class-props="max-w-[310px] lg:max-w-[400px] sm:h-[260px] h-[230px] w-fit"
              />
            </div>
            <div
              class="text-[14px] font-semibold flex sm:grid grid-cols-3 gap-[5px]"
            >
              <button
                :disabled="cards.status !== 'active'"
                :class="
                  cards.status === 'active'
                    ? 'text-[#333333] cursor-pointer border-[1px] border-[#E2E2E2] hover:bg-gray-100'
                    : 'text-[#C2C2C2] cursor-auto'
                "
                class="flex items-center justify-center grow outline-none gap-[4px] py-[5px] px-[10px] rounded-[6px]"
                @click="handleTopup('topup')"
              >
                <span class="k-send-square" />
                <div class="text-nowrap">
                  Top up
                </div>
              </button>

              <button
                :disabled="cards.status !== 'active'"
                :class="
                  cards.status === 'active'
                    ? 'text-[#333333] cursor-pointer border-[1px] border-[#E2E2E2] hover:bg-gray-100'
                    : 'text-[#C2C2C2] cursor-auto'
                "
                class="flex items-center justify-center grow outline-none gap-[4px] py-[5px] px-[10px] rounded-[6px]"
                @click="handleTransfer"
              >
                <span class="k-convert-card" />
                <div class="text-nowrap">
                  Transfer Saldo
                </div>
              </button>

              <button
                id="action"
                ref="action"
                style="border: 1px solid #E2E2E2 !important"
                class="flex outline-none items-center hover:bg-gray-100 h-[32px] justify-center cursor-pointer gap-[4px] py-[5px] px-[10px] border-[1px] border-[#E2E2E2] rounded-[6px]"
              >
                <div class="font-semibold text-[#333333] sm:inline">
                  <span class="d-none d-sm-block">Lainnya</span>
                </div>
                <img
                  class="h-[13px] md:h-[16px]"
                  height="12"
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-down-1.svg"
                  alt="send-square"
                >
              </button>

              <BPopover
                id="action"
                target="action"
                triggers="click"
                placement="bottom"
                offset="10"
              >
                <div
                  v-for="act in action"
                  :key="act.id"
                >
                  <button
                    :disabled="cards.status !== 'active' && act.id !== 3"
                    :class="
                      cards.status !== 'active' && act.id !== 3
                        ? 'text-[#C2C2C2]'
                        : 'text-[#333333]'
                    "
                    class=" cursor-pointer items-center flex gap-2"
                    @click="
                      act.onClick()
                      $root.$emit('bv::hide::popover', 'action')
                    "
                  >
                    <span :class="act.icon" />
                    <span v-if="act.id === 3">{{
                      act.label === cards.status ? 'Nonaktifkan' : 'Aktifkan'
                    }}</span>
                    <span v-if="act.id !== 3">{{ act.label }}</span>
                  </button>
                  <div class="mt-[5px] mb-[5px]" />
                </div>
              </BPopover>
            </div>
            <div
              class="flex bg-[#FEF2CD] mt-[12px] text-[#333333] items-center px-[8px] py-[10px] rounded-[8px]  justify-between"
            >
              <div class="text-[12px]">
                Saldo Komcards
              </div>
              <div class="text-[16px] font-semibold">
                {{ IDR(cards.balance, 2, 2) }}
              </div>
            </div>
          </div>
        </div>
        <div class="mt-[20px] md:mt-0">
          <div>
            <div class="flex justify-between items-center">
              <div
                class="text-[#333333] font-semibold text-[14px] md:text-[16px]"
              >
                Informasi Kartu
              </div>
              <BButton
                size="sm"
                :class="showReveal && 'active-reveal'"
                class="text-[14px] md:text-[16px]"
                :variant="
                  showReveal || loading.file || cards.status !== 'active'
                    ? 'secondary'
                    : 'primary'
                "
                :disabled="
                  showReveal || loading.file || cards.status !== 'active'
                    ? true
                    : false
                "
                @click="handleReveal"
              >
                <BSpinner
                  v-if="loading.reveal || loading.file"
                  variant="light"
                  small
                />
                <span
                  class="sm:ml-1 ml-[2px]"
                >Cek Data Kartu
                  <span
                    v-if="showReveal"
                    class="bg-[#F95031] p-[3px] rounded-full text-white"
                  >{{ timerCount }}</span></span>
                <BIcon
                  class="ml-[5px]"
                  icon="eye"
                />
              </BButton>
            </div>
            <hr class="border-[1px] my-[10px] border-[#E2E2E2] border-dashed">
            <div class="flex flex-column gap-[16px] text-[14px] md:text-[16px]">
              <div class="flex justify-between items-center">
                <div class="text-[#626262]">
                  Nomor kartu
                </div>
                <div class="flex gap-[8px] items-start">
                  <div class="text-[#333333]">
                    {{
                      !showReveal
                        ? `xxxx xxxx xxxx ${cards.card_number}`
                        : reveal.cardNumber
                    }}
                  </div>
                  <button
                    class="h-[5px] outline-none"
                    :class="showReveal && 'cursor-pointer'"
                    :disabled="!showReveal"
                    @click="handleCopyCardNumber"
                  >
                    <div
                      :class="
                        showReveal &&
                          'border-[1px] border-[#F95031] p-[2px] rounded-md'
                      "
                    >
                      <img
                        class="h-[16px]"
                        :src="
                          !showReveal
                            ? 'https://storage.googleapis.com/komerce/assets/copy.svg'
                            : 'https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/copy.svg'
                        "
                        alt="copy"
                      >
                    </div>
                  </button>
                </div>
              </div>
              <div class="flex justify-between items-center">
                <div class="text-[#626262]">
                  CVV (3-digit kode keamanan)
                </div>
                <div class="text-[#333333]">
                  {{ !showReveal ? cards.cvv : reveal.cvv }}
                </div>
              </div>
              <div class="flex justify-between items-center">
                <div class="text-[#626262]">
                  Berlaku sampai (mm/yy)
                </div>
                <div class="text-[#333333]">
                  {{ !showReveal ? cards.exp_date : reveal.expDate }}
                </div>
              </div>
            </div>
          </div>
          <div class="mt-[24px]">
            <div
              class="text-[#333333] font-semibold text-[14px] md:text-[16px]"
            >
              Detail Saldo
            </div>
            <hr class="border-[1px] my-[10px] border-[#E2E2E2] border-dashed">
            <div class="flex flex-column gap-[16px]">
              <div class="flex justify-between items-center">
                <div class="text-[#626262]">
                  Voucher iklan
                </div>
                <div class="text-[#333333]">
                  {{ IDR(cards.voucher_balance, 2, 2) }}
                </div>
              </div>
              <div class="flex justify-between items-center">
                <div class="flex gap-[7px] items-center">
                  <div class="text-[#626262]">
                    Saldo pending
                  </div>
                  <div id="tooltip-button-variant">
                    <img
                      class="h-[16px]"
                      src="https://storage.googleapis.com/komerce/assets/svg/info-circle.svg"
                      alt="info"
                    >
                    <b-tooltip
                      target="tooltip-button-variant"
                      variant="light"
                      class="bg-red-100"
                    >Saldo ini belum dapat ditarik, namun bisa digunakan untuk
                      transaksi. Pihak penyedia layanan segera memprosesnya
                      kembali ke kartu debit kamu</b-tooltip>
                  </div>
                </div>
                <div class="text-[#333333]">
                  {{ IDR(cards.hold_balance, 2, 2) }}
                </div>
              </div>
              <div class="flex justify-between items-center">
                <div class="text-[#333333]">
                  <div class="text-[#626262]">
                    Spending harian
                  </div>
                  <div class="text-[10px] text-[#828282]">
                    max. {{ IDR(cards.daily_spending_limit, 2, 2) }}
                  </div>
                </div>
                <div class="text-[#333333]">
                  {{ IDR(cards.daily_spending, 2, 2) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BOverlay>
    <hr class="border-[1px] my-[10px] border-[#E2E2E2] border-dashed">
    <BRow class="pl-[15px] pr-[15px] items-center justify-between mt-1">
      <div class="text-black font-semibold m-0 text-2xl">
        Mutasi
      </div>
      <div class="flex items-center flex-row">
        <div class="ml-1">
          <BButton
            id="filter"
            size="sm"
            variant="primary"
          >
            <img
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/filter-search.svg"
              alt="Komerce"
              width="20"
            >
          </BButton>
          <BPopover
            target="filter"
            triggers="click"
            placement="bottomleft"
          >
            <label>Tanggal</label>
            <BRow>
              <BCol md="6">
                <FlatPickr
                  v-model="startDate"
                  class="form-control"
                  placeholder="Mulai Dari"
                  :config="{
                    mode: 'single',
                    altInput: true,
                    altFormat: 'j/n/Y',
                    dateFormat: 'Y-m-d',
                  }"
                  @input="setStartDate"
                />
              </BCol>
              <BCol md="6">
                <FlatPickr
                  v-model="endDate"
                  class="form-control"
                  placeholder="Sampai Dengan"
                  :config="{
                    mode: 'single',
                    altInput: true,
                    altFormat: 'j/n/Y',
                    dateFormat: 'Y-m-d',
                    minDate: startDate,
                  }"
                  @input="setEndDate"
                />
              </BCol>
            </BRow>
            <label class="mt-1">Jenis Transaksi</label>
            <VSelect
              v-model="transactionType"
              :options="optionsTransactionType"
              :reduce="option => option.value"
              label="name"
              @input="setTransactionType"
            >
              <span
                slot="no-options"
                @click="$refs.select.open = false"
              />
            </VSelect>

            <BRow class="mx-auto mt-2">
              <BButton
                variant="outline-primary"
                class="mr-1"
                @click.prevent="resetFilter()"
              >
                Reset
              </BButton>
              <BButton
                variant="primary"
                @click.prevent="getMutation($route.params.id)"
              >
                Terapkan
              </BButton>
            </BRow>
          </BPopover>
        </div>
      </div>
    </BRow>
    <Mutation
      :scroll="'scroll'"
      :loading="loading.mutation"
      :items="listMutation"
      :fields="fields"
      :status-class="statusClass"
      :nominal-class="nominalClass"
    />

    <BModal
      id="modal-confirm-pin"
      ref="modal-confirm-pin"
      no-stacking
      centered
      hide-footer
      hide-header
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      size="md"
    >
      <BRow class="justify-center my-1 flex flex-column">
        <BCol class="d-flex justify-content-center">
          <div class="text-black font-semibold text-xl">
            Verifikasi PIN
          </div>
        </BCol>
        <BCol class="d-flex justify-content-center mt-1">
          <div class="text-black text-md">
            Mohon verifikasi identitias kamu dengan memasukkan PIN
          </div>
        </BCol>
        <BCol class="d-flex justify-content-center mt-2 mb-1">
          <CodeInput
            :style="
              isVisibility
                ? '-webkit-text-security: disc'
                : '-webkit-text-security: none'
            "
            :loading="false"
            class="input"
            :type="visibilityPin"
            :field-width="40"
            @change="handleChange"
          />
        </BCol>
        <BCol class="d-flex justify-content-center">
          <BButton
            variant="flat-primary"
            class="btn-icon"
            @click="visiblePin"
          >
            {{ isVisibility ? 'Tampilkan' : 'Sembunyikan' }}
          </BButton>
        </BCol>
        <BCol
          class="d-flex my-1 justify-content-center font-bold text-primary cursor-pointer"
          @click="$router.push('/setting-kompship/pin')"
        >
          Lupa PIN?
        </BCol>
        <BCol class="d-flex justify-content-center text-primary">
          <small class="text-danger text-center">{{ errorMessage }}</small>
        </BCol>
        <BCol class="d-flex justify-content-center mt-1 pb-2 gap-4">
          <BButton
            variant="outline-primary"
            @click="handleClose()"
          >
            Kembali
          </BButton>
          <BButton
            :variant="
              pin.length < 6 || loading.reveal || loading.status
                ? 'secondary'
                : 'primary'
            "
            :disabled="pin.length < 6 || (loading.status && loading.reveal)"
            type="submit"
            @click.prevent="handleOpenPin"
          >
            <BSpinner
              v-if="loading.reveal"
              variant="light"
              small
            />
            Submit
          </BButton>
        </BCol>
      </BRow>
    </BModal>

    <ModalTopup
      :card-data="cards"
      :get-mutation="getMutation"
      :get-cards="getCards"
      :balance-data="balanceData"
      :balance-detail="balanceDetail"
      :modal-type="modalType"
    />

    <ModalTransfer
      :id="parseInt(cardId, 10)"
      :info-balance="balanceDetail"
    />

    <ModalVerificationKyc
      :id-modal="idModal"
      :title="'Kamu perlu verifikasi KTP'"
      :desc="
        'Yuk ajukan verifikasi KTP dan Selfie dengan KTP sekarang juga untuk memvalidasi keaslian akunmu dan melihat data kartumu!'
      "
      :source="'pending'"
      :submit="routeVerification"
    />
    <ModalChangeCardName
      :card="cards"
      :get-cards="getCards"
    />
  </BCard>
</template>

<script>
import { IDR } from '@/libs/currency'
import { newAxiosIns, komshipAxiosIns } from '@/libs/axios'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import FlatPickr from 'vue-flatpickr-component'
import VSelect from 'vue-select'
import CodeInput from 'vue-verification-code-input'
import { columnConfigDetail, optionsTransactionType } from './config'
import '@/@core/scss/vue/libs/vue-flatpicker.scss'
import 'vue-select/dist/vue-select.css'
import {
  Mutation,
  ModalTopup,
  ModalVerificationKyc,
  CCDetail,
  ModalTransfer,
  ModalChangeCardName,
} from './components'

export default {
  components: {
    Mutation,
    VSelect,
    FlatPickr,
    ModalTopup,
    CodeInput,
    ModalVerificationKyc,
    CCDetail,
    ModalTransfer,
    ModalChangeCardName,
  },
  data() {
    return {
      IDR,
      stateForOpenPIN: '',
      loading: {
        mutation: false,
        status: false,
        reveal: false,
        card: false,
        file: false,
        informationBalance: false,
      },
      listMutation: [],
      cards: {
        label: null,
        card_network: null,
        id: null,
        balance: null,
        card_number: null,
        exp_date: null,
        cvv: null,
        status: null,
        amount: null,
        hold_balance: null,
        voucher_balance: null,
        daily_spending: null,
        daily_spending_limit: null,
      },
      reveal: {
        cardNumber: null,
        cvv: null,
        expDate: null,
      },
      showReveal: false,
      isVisibility: true,
      visibilityPin: 'number',
      errorMessage: '',
      pin: '',
      cardId: this.$route.params.id,
      startDate: moment()
        .startOf('month')
        .format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      transactionType: '',
      limit: 10,
      offset: 0,
      timerCount: 60,
      fields: columnConfigDetail,
      optionsTransactionType,
      idPartner: this.$store.state.auth.userData.id,
      balanceData: null,
      lastData: false,
      label: null,
      labelOriginal: null,
      modalType: null,
      balanceDetail: {},
      idModal: 'modal-requested-kyc',
      action: [
        {
          id: 1,
          label: 'Ubah Nama',
          icon: 'k-card-edit',
          onClick: () => {
            this.$bvModal.show('modal-change-card-name')
          },
        },
        {
          id: 2,
          label: 'Tarik Saldo',
          icon: 'k-receive-square',
          onClick: () => {
            this.checkReveal('withdrawal')
            this.checkBalance()
          },
        },
        {
          id: 3,
          label: 'active',
          icon: 'k-card-remove',
          onClick: () => {
            this.stateForOpenPIN = 'active-inactive'
            this.$bvModal.show('modal-confirm-pin')
          },
        },
      ],
    }
  },
  computed: {
    setTransactionType() {
      return type => {
        this.transactionType = type
      }
    },
    setStartDate() {
      return date => {
        this.startDate = date
      }
    },
    setEndDate() {
      return date => {
        this.endDate = date
      }
    },
    statusClass() {
      return status => {
        if (status === 'success') {
          return 'text-[#34A770]'
        }
        if (status === 'failed') {
          return 'text-[#E31A1A]'
        }
        if (status === 'pending') {
          return 'text-[#828282]'
        }
        return ''
      }
    },
    nominalClass() {
      return type => {
        if (type === 'credit') {
          return 'text-[#34A770]'
        }
        if (type === 'debit') {
          return 'text-[#E31A1A]'
        }
        if (type === 'cashback') {
          return 'text-[#CC9212]'
        }
        return ''
      }
    },
    resetFilter() {
      return () => {
        this.startDate = moment().format('YYYY-MM-DD')
        this.endDate = moment().format('YYYY-MM-DD')
        this.transactionType = ''
        this.getMutation(this.$route.params.id)
        this.offset = 0
      }
    },

    handleTopup() {
      this.$root.$emit('bv::hide::popover', 'action')
      return value => {
        this.$bvModal.show('modal-topup')
        if (value !== 'withdrawal') {
          this.getBalance()
        }
        this.modalType = value
      }
    },
    visiblePin() {
      return () => {
        this.isVisibility = !this.isVisibility
        this.visibilityPin = this.isVisibility ? 'number' : 'text'
      }
    },
    handleChange() {
      return value => {
        this.pin = value
      }
    },
    handleClose() {
      return () => {
        this.$bvModal.hide('modal-confirm-pin')
        this.errorMessage = ''
        this.pin = ''
      }
    },
  },
  watch: {
    cards: {
      handler(e) {
        if (e.balance === null) {
          this.getBalanceMonit()
        }
      },
      deep: true,
    },
  },
  created() {
    window.onscroll = () => {
      if (
        window.innerHeight + window.scrollY
          >= document.getElementById('scroll').offsetHeight
        && !this.loading.mutation
      ) {
        this.getNextDataMutation()
      }
    }
  },
  mounted() {
    this.getCards()
    this.getMutation()
  },
  methods: {
    handleReveal() {
      this.stateForOpenPIN = 'revealCard'
      this.checkReveal('reveal')
    },
    async getBalanceMonit() {
      this.loading.getBalanceMonit = true
      const url = '/komcards/api/v1/card/balance-cards'
      try {
        const res = await newAxiosIns.request({
          method: 'post',
          data: {
            card_ids: [parseInt(this.cardId, 10)],
          },
          url,
        })

        this.cards.balance = res.data.data[0].balance

        this.loading.getBalance = false
        return
      } catch (error) {
        this.loading.getBalance = false
        this.$toast_error({
          message: 'Gagal load data get balance',
        })
      }
    },
    async activeInactiveCard() {
      const form = new FormData()
      form.append('card_id', this.cardId)
      form.append('pin', this.pin)

      this.loading.status = true
      const activeUrl = `/komcards/api/v1/card/${this.cardId}/activated`
      const inactiveUrl = `/komcards/api/v1/card/${this.cardId}/deactivated`
      const url = this.cards.status !== 'active' ? activeUrl : inactiveUrl

      await newAxiosIns
        .request({
          url,
          method: 'put',
          data: form,
        })
        .then(() => {
          const activeMessage = 'Kartu Berhasil di Aktifkan'
          const inactiveMessage = 'Kartu Berhasil di Non-Aktifkan'

          const message = this.cards.status !== 'active' ? activeMessage : inactiveMessage

          this.loading.activeInactive = false
          this.$bvModal.hide('modal-confirm-pin')
          this.$toast_success({ message })
          this.getCards()
          this.errorMessage = ''
          this.pin = ''
          this.loading.status = false
        })
        .catch(err => {
          const { message } = err.response.data
          if (message === 'wrong pin numbers') {
            this.errorMessage = 'PIN yang anda masukan salah'
          } else {
            this.$toast_error({ message })
          }
          this.loading.status = false
        })
    },
    handleOpenPin() {
      if (this.stateForOpenPIN === 'revealCard') {
        return this.revealCard()
      }

      return this.activeInactiveCard()
    },
    handleCopyCardNumber() {
      navigator.clipboard.writeText(this.reveal.cardNumber)
      this.$toast_success({ message: 'Nomor kartu berhasil disalin' })
    },
    handleTransfer() {
      this.checkBalance()
      this.$root.$emit('bv::hide::popover', 'action')
      this.$bvModal.show('modal-transfer')
    },

    async getCards() {
      this.loading.card = true
      const url = `komcards/api/v1/card/${this.cardId}/detail`
      await newAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data

          // handle change data from BE
          this.cards.label = data.label
          this.cards.id = parseInt(this.cardId, 10)
          this.cards.status = data.status
          this.cards.card_network = data.info.card_network
          this.cards.card_number = data.info.card_number
          this.cards.exp_date = data.info.exp_date
          this.cards.cvv = data.info.cvv
          this.cards.balance = null
          this.cards.hold_balance = data.balance.hold_balance
          this.cards.voucher_balance = data.balance.voucher_balance
          this.cards.daily_spending = data.spending.daily_spending
          this.cards.daily_spending_limit = data.spending.daily_spending_limit

          this.loading.card = false
        })
        .catch(() => {
          this.loading.card = false
          this.$toast_error({
            message: 'Gagal memuat data kartu, silahkan coba lagi',
          })
        })
    },
    async getMutation() {
      this.loading.mutation = true
      this.offset = 0
      const params = {
        start_date: this.startDate,
        end_date: this.endDate,
        transaction_type: this.transactionType,
        offset: this.offset,
        limit: this.limit,
        card_id: this.cardId,
      }
      const url = '/komcards/api/v1/card/mutation'
      await newAxiosIns
        .get(url, { params })
        .then(res => {
          const { data } = res.data
          this.listMutation = data
          this.loading.mutation = false

          this.offset = data.length
          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(() => {
          this.loading.mutation = false
          this.$toast_error({
            message: 'Gagal memuat data mutasi, silahkan coba lagi',
          })
        })
    },
    getNextDataMutation() {
      if (!this.lastData) {
        this.loading.mutation = true
        const params = {
          start_date: this.startDate,
          end_date: this.endDate,
          transaction_type: this.transactionType,
          offset: this.offset,
          limit: this.limit,
          card_id: this.cardId,
        }
        const url = '/komcards/api/v1/card/mutation'
        newAxiosIns
          .get(url, { params })
          .then(res => {
            const { data } = res.data
            this.loading.mutation = false
            this.listMutation.push(...data)
            this.offset += data.length
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(() => {
            this.loading.mutation = false
            this.$toast_error({
              message: 'Gagal memuat data mutasi, silahkan coba lagi',
            })
          })
      }
    },
    async revealCard() {
      this.loading.reveal = true
      const form = new FormData()
      form.append('pin', this.pin)
      form.append('card_id', this.cardId)
      const url = `/komcards/api/v1/card/${this.cardId}/show`
      await newAxiosIns
        .request({ url, data: form, method: 'post' })
        .then(res => {
          const { data } = res.data
          this.loading.reveal = false
          this.reveal = data
          this.showReveal = true
          this.pin = ''
          this.errorMessage = ''
          this.$bvModal.hide('modal-confirm-pin')
          this.decryptedCard(data.encrypted_data)
          this.countDownTimer()
        })
        .catch(err => {
          const { message } = err.response.data
          this.loading.reveal = false

          if (message === 'wrong pin numbers') {
            this.errorMessage = 'PIN yang anda masukan salah'
          } else {
            this.errorMessage = message
          }
        })
    },
    async getBalance() {
      const url = `/v1/dashboard/partner/balanceSummary?partner_id=${this.idPartner}`
      await komshipAxiosIns
        .get(url)
        .then(res => {
          const {
            data: { balance },
          } = res.data
          this.balanceData = balance
          if (this.showModalTopup === true) {
            this.$bvModal.show('modal-topup')
          }
        })
        .catch(() => {
          this.$toast_error({
            message: 'Gagal memuat data balance, silahkan coba lagi',
          })
        })
    },
    async checkBalance() {
      this.loading.informationBalance = true

      const url = `/komcards/api/v1/card/${this.cardId}/info-balance`

      await newAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.loading.informationBalance = false
          const balanceDetail = { ...data, balance: data.monit_balance }
          this.balanceDetail = balanceDetail
        })
        .catch(() => {
          this.loading.informationBalance = false
          this.$toast_error({
            message: 'Gagal memuat data balance, silahkan coba lagi',
          })
        })
    },
    async checkReveal(type) {
      this.stateForOpenPIN = 'revealCard'
      this.loading.file = true
      const url = '/auth/api/v1/partner/request-verification-ktp/latest-history'
      await newAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.loading.file = false
          this.kyc = data
          if (data.status === 'approved') {
            return type === 'reveal'
              ? this.$bvModal.show('modal-confirm-pin')
              : this.handleTopup('withdrawal')
          }
          if (data.status === 'requested') {
            return this.$toast(
              {
                component: ToastificationContentVue,
                props: {
                  title: 'Fitur Ini Belum Bisa Digunakan',
                  icon: 'AlertCircleIcon',
                  text:
                    'Kamu sudah mengajukan Verifikasi KTP agar dapat menggunakan fitur ini. Silahkan tunggu konfirmasi lebih lanjut selama 1x24 jam',
                  variant: 'danger',
                },
              },
              { timeout: 2000 },
            )
          }
          if (data.status === 'not requested') {
            return this.$bvModal.show(this.idModal)
          }
          return this.$router.push('/verification-ktp')
        })
        .catch(() => {
          this.loading.file = false
        })
    },
    countDownTimer() {
      const timer = setInterval(() => {
        if (this.timerCount > 0) {
          this.timerCount -= 1
        } else {
          this.showReveal = false
          this.timerCount = 60
          clearInterval(timer)
        }
      }, 1000)
    },
    copyCardNumber() {
      if (this.showReveal) {
        navigator.clipboard.writeText(this.reveal.cardNumber)
        this.$toast_success({ message: 'Nomor kartu berhasil disalin' })
      }
    },
    routeVerification() {
      this.$router.push('/verification-ktp')
    },
    decryptedCard(encrypted) {
      const decode = atob(encrypted)
      const numberArray = decode.split('%%').map(Number)
      const resultArray = numberArray.map((value, index) => {
        const result = value / (3 * (index + 1))
        if (index === 2) {
          return result.toString()
        }
        if ([0, 1, 3, 4, 5].includes(index)) {
          return result < 1000
            ? result.toString().padStart(4, '0')
            : result.toFixed(0)
        }
        return result.toFixed(0)
      })
      const cardNumber = resultArray
        .slice(0, 2)
        .concat(resultArray.slice(4, 6))
        .join('')
        .match(/.{1,4}/g)
        .join(' ')
      const cvv = resultArray[2].padStart(3, 0)
      const expDate = `${resultArray[3].substring(
        0,
        2,
      )}/${resultArray[3].substring(2)}`

      this.reveal.cardNumber = cardNumber
      this.reveal.cvv = cvv
      this.reveal.expDate = expDate
    },
  },
}
</script>

<style scoped>
#action {
  border: none !important;
}

.wrapper_card {
  display: grid;
  gap: 20px;
}

@media screen and (min-width: 991px) {
  .wrapper_card {
    grid-template-columns: 4fr 8fr;
  }
}

@media screen and (min-width: 1000px) {
  .wrapper_card {
    grid-template-columns: 4fr 8fr;
  }
}

.active-reveal {
  background-color: white !important;
  color: #626262 !important;
  font-weight: 500 !important;
  opacity: 1 !important;
}
</style>
